@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&family=Cormorant+Garamond:wght@300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&family=Cormorant+Garamond:wght@300;600&family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300;1,400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .header-item {
    @apply hover:underline underline-offset-2;
    /* @apply hover:underline underline-offset-2 text-2xl text-pr-purple font-['Allura']; */
  }

  .header-item-active {
    @apply underline;
  }

  /* button base styles */
  .button {
    @apply py-3 px-8 sm:px-12 text-lg
    w-fit hover:cursor-pointer
    transition-colors duration-200 hover:text-pr-blue;
  }

  .button-border {
    @apply
    bg-white border-black border-2 
    hover:bg-black hover:text-white;
  }

  .button-plain {
    @apply hover:scale-110 transition-transform 
  }

  .contact-dropdown-link {
    @apply
    text-black text-center
    border rounded border-black p-0.5 cursor-pointer;
  }

  .layout-container {
    @apply
    mx-auto w-10/12 md:w-3/5;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.landing-img {
  object-fit: cover;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.button-bg-transparent {
  background: #ffffff85;
}

.mobile-menu-btn {
  pointer-events: auto
}

@media (min-width: 640px) {
  .mobile-menu-btn {
    display: none !important;
  }
}

.mobile-menu-btn:hover {
  cursor: pointer;
}

@keyframes onHoverAnimation {
  0% {
      background-color: rgb(0, 0, 0);  
  }
  100% {
      background-color: rgb(16 185 129);
  }
}
